import React, { useState, createContext, useContext } from 'react';

const OnRenderedContext = createContext();

const OnRenderedProvider = ({ children }) => {
  const [onRendered, setOnRendered] = useState(false);

  return <OnRenderedContext.Provider value={[onRendered, (value, who) => console.log("setOnRendered", value, "from", who, new Date().toISOString()) || setOnRendered(value)]}>{children}</OnRenderedContext.Provider>;
};

const useOnRenderedContext = () => {
  const context = useContext(OnRenderedContext);

  if (!context) throw Error('No OnRenderedContext');
  return context;
};

export { useOnRenderedContext, OnRenderedProvider };
