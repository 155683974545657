const contattaci = {
  en: {
    translation: {
    }
  },
  it: {
    translation: {
    }
  }
};

export default contattaci;
