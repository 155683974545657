const header = {
  en: {
    translation: {
      home: 'Home',
      services: 'Services',
      who_are_we: 'Who are we',
      contact_us: 'Contact us',
      individual_firm_financing: 'Individual firm financing',
      advance_invoices: 'Advance invoices',
      business_consultancy: 'Business consultancy',
      company_financing: 'Company financing',
      estimator: 'Estimator',
      why_we: 'Perchè sceglierci'
    }
  },
  it: {
    translation: {
      home: 'Home',
      services: 'Servizi',
      who_are_we: 'Chi siamo',
      contact_us: 'Contattaci',
      individual_firm_financing: 'Finanziamenti ditte individuali',
      advance_invoices: 'Anticipo fatture',
      business_consultancy: 'Consulenza aziendale',
      company_financing: 'Finanziamenti società',
      estimator: 'Preventivatore',
      why_we: 'Perchè sceglierci'
    }
  }
};

export default header;
