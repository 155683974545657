export default function getAggregateRatingSchema(product) {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'AggregateRating',
    ratingCount: 2180,
    reviewCount: 2180,
    bestRating: 5.0,
    worstRating: 0,
    ratingValue: 5.0,
    ratingExplanation: 'Valutato da trustpilot.com sulla base di oltre 2180 recensioni',
    url: 'https://it.trustpilot.com/review/www.euroansa.it',
  };

  return schema;
}
