const footer = {
  en: {
    translation: {
      footer_description:
        'Fai crescere la tua realtà. Su TassoMutuo trovi decine di proposte selezionate, di banche ed istituti convenzionati, per valorizzare la tua vita scegliendo la migliore soluzione.',
      services_title: 'Our Services',
      links_title: 'Our Links',
      contacts_title: 'Contact Us',
      web_development: 'Web Development',
      mobile_development: 'Mobile Development',
      cloud_technologies: 'Cloud Technologies',
      ui_ux_desins: 'UI/UX Desins',
      terms_conditions: 'Terms & Condition',
      privacy_policy: 'Privacy Policy',
      imprint: 'Imprint',
      legal: 'Legal',
      cookie_policy: 'Cookie Policy',
      trasparency: 'Trasparency',
      knoweuroansa: 'Knowing Euroansa',
      term_condition: 'Termini e condizioni',
      restricted_area: 'Area riservata',
    },
  },
  it: {
    translation: {
      footer_description:
        'Fai crescere la tua realtà. Su TassoMutuo trovi decine di proposte selezionate, di banche ed istituti convenzionati, per valorizzare la tua vita scegliendo la migliore soluzione.',
      services_title: 'I Nostri Servizi',
      links_title: 'I Nostri Link',
      contacts_title: 'Contattaci',
      web_development: 'Sviluppo Web',
      mobile_development: 'Sviluppo Mobile',
      cloud_technologies: 'Tecnologie Cloud',
      ui_ux_desins: 'UI/UX Design',
      terms_conditions: 'Termini & Condizioni',
      privacy_policy: 'Privacy Policy',
      imprint: 'Imprint',
      legal: 'Legal',
      cookie_policy: 'Cookie Policy',
      trasparency: 'Trasparenza',
      knoweuroansa: 'Conosci Euroansa',
      term_condition: 'Termini e condizioni',
      restricted_area: 'Area riservata',
    },
  },
};

export default footer;
