import '../styles/globals.css';
import '../styles/dynamic-page.css';

import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useReportWebVitals } from 'next/web-vitals';
import { I18nextProvider } from 'react-i18next';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import i18next from '../language/i18next.js';
import config from '../config/config.js';
import useStorage from '../hooks/useStorage.ts';

import mixpanel from 'mixpanel-browser';

import { OnRenderedProvider } from '../context/OnRenderedContext';
import { PopupCtaContextProvider } from '../context/PopupCtaContext';
import AppChildren from './appChildren';

// import TagManager from 'react-gtm-module';
import Head from 'next/head';

const cookie_m = {
  it: {
    description:
      "I nostri cookie di profilazione e quelli installati da terze parti ci aiutano a migliorare i nostri servizi online anche per proporti pubblicità in linea con le tue preferenze. Se ne accetti l'uso continua a navigare sul nostro sito. Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie clicca su",
    button: 'Accetto',
  },
  en: {
    description:
      'This website uses profiling cookies, including of third parties, to send you advertisements in line with your preferences. By continuing to browse the site you agree to the use of such cookies. If you would like to have more information or deny your consent to all or some of the cookies click on',
    button: 'Accept',
  },
};

function MyApp(props) {
  const { Component, pageProps } = props;
  const router = useRouter();
  const [language, setLanguage] = useState(router?.query?.lang);
  const { setItem } = useStorage();

  useReportWebVitals(metric => {
    console.log('metric', metric);
  });

  useEffect(() => {
    // // console.log("primo render", router.pathname);
    // apiView(router.pathname);
    // console.log('router.query', router.query);
    if (router.query && router.query.questiontype) {
      setItem('questiontype', router.query.questiontype, 'local');
    }
  }, []);

  const diff = (diffMe, diffBy) => diffMe.split(diffBy).join('');

  const { mixpanelId, mixpanelDebug } = config;
  mixpanel.init(mixpanelId, { debug: mixpanelDebug });

  //AGGIUNGI EVENTO MIXPANEL OVUNQUE SERVA
  // mixpanel.track('test', {
  //   prova: '1',
  // });

  return (
    <I18nextProvider i18n={i18next}>
      <Head>
        <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
        <meta name="facebook-domain-verification" content="41xmunroenod5jlcrb3yfhh71uwgw7" />
      </Head>
      <OnRenderedProvider>
      <PopupCtaContextProvider>
        <AppChildren Component={Component} pageProps={pageProps} />
      </PopupCtaContextProvider>
      </OnRenderedProvider>
    </I18nextProvider>
  );
}

MyApp.getInitialProps = async ({ Component, ctx, router }) => {
  let pageProps = {};

  if (ctx && ctx.req) {
    const { url } = ctx.req;

    const splitted = url.split('/');
    if (url.includes('sitemap.xml')) {
    }

    let path = '';
    if (splitted[2] && splitted[2].indexOf('?')) {
      path = splitted[2].split('?')[0];
    } else if (splitted[2]) {
      path = splitted[2];
    }

    if (path && !config.supportedRoute.includes(path)) {
      const { res } = ctx;
    }
  }
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  return { pageProps };
};

export default MyApp;
