import React, { useState, createContext, useContext } from 'react';

const PopupCtaContext = createContext();

const PopupCtaContextProvider = ({ children }) => {
  const [popupCtaContext, setPopupCtaContext] = useState(false);

  return <PopupCtaContext.Provider value={[popupCtaContext, setPopupCtaContext]}>{children}</PopupCtaContext.Provider>;
};

const usePopupCtaContext = () => {
  const context = useContext(PopupCtaContext);

  if (!context) throw Error('No PopupCtaContext');
  return context;
};

export { usePopupCtaContext, PopupCtaContextProvider };
