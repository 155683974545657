const mutuoLiquidita = {
  en: {
    translation: {
      main_title: 'Lorem ipsum dolor sit amet,',
      main_text_1: 'Con TassoMutuo scegli il migliore Mutuo per le tue esigenze!',
      main_text_2: 'TassoMutuo',
      main_text_3: ' combines the solutions of dozens of banks and affiliated institutions to help your reality.',
      feasibility_1: 'Ask ',
      feasibility_2: 'now',
      feasibility_3: ' the feasibility of the operation:',
      company_financing_title: 'Company financing',
      company_financing_text_1:
        'For companies, individuals or capital, it is possible to apply for financing to accelerate growth, face expenses in a difficult period or invest in a new project.',
      company_financing_text_2: 'There are many different solutions from many different institutes. Request feasibility without obligation!',
      individual_company_financing_title: 'Individual company financing',
      individual_company_financing_text_1:
        'If you are an individual company, it is possible to request an ad-hoc loan, not bound to any purpose and therefore to be used as you prefer.',
      individual_company_financing_text_2:
        'It is possible to request it without obligation in a minute and then be contacted by a professional who has many solutions available from many institutes.',
      advance_invoices_title: 'Advance invoices',
      advance_invoices_text:
        'The advance invoice is a type of financing that anticipates the liquidity deriving from invoices with payment over time, to have the funds immediately available without waiting and avoid liquidity crises.',
      business_consulting_title: 'Business consulting',
      business_consulting_text_1:
        'Each company or firm certainly has the opportunity to improve its situation through the use of appropriate financial instruments, which are sometimes ignored.',
      business_consulting_text_2:
        'We have a network of professionals located throughout the country who can help you grow in the best way. Request a contact without any obligation!',
      how_does_it_work: 'Who we are?',
      oltre_text_1:
        "TassoMutuo è un portale digitale per <b>la comparazione dei migliori mutui sul mercato</b>, con l'obiettivo di raccogliere richieste di <b>finanziamento</b> da parte di <b>privati</b> intenzionati ad acquistare casa.",
      oltre_text_2:
        'Ogni richiesta viene gestita in una prima fase da un <b>back-office</b> dedicato, che svolge le prime verifiche di finanziabilità per poi essere inviato ad un <b>consulente del credito Euroansa</b> di zona per il contatto vero e proprio.',
      oltre_text_3:
        '<b>Euroansa</b> è una grande realtà italiana nell’ambito della mediazione del credito e conta oltre <b>800 professionisti</b> convenzionati con molti <b>Istituti Finanziari e Bancari</b>, ed offre quindi una costellazione di servizi finanziari a portafoglio.',
      active_consultants: 'Active consultants',
      covered_italian_regions: 'Covered Italian Regions',
      affiliated_institutes: 'Affiliated Institutes',
      financial_products_available: 'Financial Products Available',
      request_professionist:
        'Apply <span class="color-red">online in one minute</span> and soon receive contact from a professional <span class="color-red">without obligation!</span>',
      send_request_to_our_team: 'Send your <span class="color-red">request</span> to our Team',
      send_request_to_our_team_text:
        'just enter your company name and VAT number. A specialized team will carry out an analysis to immediately evaluate the feasibility of the operation',
      receive_contact: 'Get contact from a professional',
      receive_contact_text:
        'In about 48 hours you will be contacted by a professional who will already have the first values ​​for your reality in hand.',
      find_solution: 'Find your ideal solution',
      find_solution_text: 'Together with the expert, choose the best solution to your needs and get it quickly.',
      name: 'Name',
      last_name: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      vat_id: 'VAT ID',
      toc: 'By sending this message, you confirm that you have read and agreed to our privacy-policy.',
      toc_privacy_policy: 'privacy-policy',
      error_name: 'Please add your name',
      error_last_name: 'Please add your last name',
      error_email: 'Please add or check your email address',
      error_phone: 'Please add or check your phone number',
      error_vat_id: 'Please add or check your vat ID',
      error_toc: 'Please accept our terms and conditions',
      form_success_title: 'Request sent with <span class="color-green">success!</span>',
      form_success_text: "Tieni d'occhio il tuo cellulare un nostro consulente ti contatterà in 5 minuti!",
      form_success_text_night: "Ciao! Il ricontatto in 5 minuti è attivo dalle 9:00 alle 20:00, verrai pertanto contattato entro le 9:15. Grazie per la comprensione.",
      form_error_title: '<span class="color-red">Error</span> in the request',
      form_error_text: 'It seems like there was an error trying to send your request, please try again shortly',
      estimator_label:
        'Do you need a <span class="color-red">Mutual ?</span><br />try our estimator <span style="font-style: italic;"><span class="color-red">O</span>ltre.it</span> and find the solution that fits best your needs!',
      lorem:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      text_lorem_1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      text_lorem_2:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      text_lorem_3: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      conventioned_1: 'Oltre ',
      conventioned_2: '130 Istituti Finanziari',
      conventioned_3: ' e ',
      conventioned_4: 'Bancari convenzionati',
      all_italy: 'Tutta Italia',
      coverage: 'Coverage',
      head_title: 'TassoMutuo: Ti affianchiamo nella scelta del mutuo online',
      head_description:
        'Confronta e risparmia sul mutuo online con TassoMutuo. Un Consulente ti affiancherà per tutto il percorso. Confronta le rate per la casa: al resto pensiamo noi. ',
      head_keywords: 'Mutuo, Mutuo online, Oltre, Casa',
      contact_us_section_title: 'Come possiamo aiutarti?',
      contact_us_section_description: 'Hai bisogno di informazioni? Non esitare a contattarci.',
      contact_us_section_write_us: 'Scrivici',
      how_does_it_work_title: '<span class="color-blue">Come funziona</span> TassoMutuo?',
      how_does_it_work_text_1: 'Rispondi ad alcune domande, ci servono per fornirti la migliore proposta di Mutuo',
      how_does_it_work_text_2: 'Confronta le diverse proposte e scegli il miglior mutuo per te',
      how_does_it_work_text_3: 'Parla gratuitamente ed entro 5 minuti con il tuo consulente che analizzerà la tua pratica',
      advantages_title: 'Quali sono i vantaggi di <span class="color-light-green">TassoMutuo?</span>',
      advantages_text_1: 'TassoMutuo è un progetto Euroansa che conta oltre 800 professionisti convenzionati con oltre 130 Istituti Finanziari e Bancari',
      advantages_text_2: 'In soli 5 minuti siamo al tuo fianco per valutare la tua pratica e consigliarti sulle condizioni migliori da scegliere',
      advantages_text_3: 'Abbiamo lo stesso obiettivo, farti ottenere il mutuo alle migliori condizioni di mercato',
      advantages_text_4: 'I nostri consulenti hanno successo in più dell\'80% di pratiche contro una media nazionale di circa il 50%',
      main_banner_title: 'Scegli tra i migliori mutui quello perfetto per te.',
      main_banner_subtitle: 'Un consulente sarà al tuo fianco in 5 minuti!',
      main_banner_input_label: 'Inserisci l\'importo del tuo mutuo',
      main_banner_input_text: 'Confronta i Mutui delle migliori banche e trova i Tassi più convenienti',
      main_banner_input_button: 'Calcola',
      most_asked_mutuals: 'I nostri mutui più <span class="color-light-green">richiesti</span>',
      calculate_payment: 'Calcola la tua rata',
      reviews_title: 'Siamo giovani, ma abbiamo già <span class="color-light-green">aiutato tanti come te</span>',
      reviews_description: 'Valutata <b>4.9</b> su 5 sulla base di <span style="text-decoration: underline;">oltre 800 recensioni</span>. Le nostre ultime recensioni.',
    },
  },
  it: {
    translation: {
      head_title: 'Cerchi un mutuo Casa + Liquidità? Scegli quello perfetto per te.',
      head_description:
        'Cerchi un mutuo Casa + Liquidità? Scegli quello perfetto per te. Un consulente sarà al tuo fianco in 5 minuti!',
      head_keywords: 'Mutuo, Mutuo online, mutuo liquidità',
      main_banner_title: 'Cerchi un mutuo Casa + Liquidità? Scegli quello perfetto per te.',
      main_banner_subtitle: 'Un consulente sarà al tuo fianco in 5 minuti!',

      main_title_2: 'Scegli tra i migliori mutui',
      main_title: 'quello perfetto per te.',
      main_title_3: 'Un consulente sarà al tuo fianco in 5 minuti! ',
      main_text_1: 'Con TassoMutuo scegli il migliore Mutuo per le tue esigenze!',
      main_text_2: 'TassoMutuo ',
      main_text_3: 'scegli il migliore Mutuo Casa per le tue esigenze.',
      cta: 'Utilizza il preventivatore e calcola la tua rata ora',
      feasibility_1: 'Richiedi ',
      feasibility_2: 'subito',
      feasibility_3: ' la fattibilità dell’operazione:',
      company_financing_title: 'Finanziamenti Società',
      company_financing_text_1:
        'Per società, siano queste di persone o di capitale, è possibile richiedere finanziamenti per accelerare la crescita, affrontare spese in un periodo difficile o investire in un nuovo progetto.',
      company_financing_text_2: 'Esistono molte soluzioni differenti da molti differenti istituti. Richiedi la fattibilità senza impegno!',
      individual_company_financing_title: 'Finanziamenti ditte Individuali',
      individual_company_financing_text_1:
        'Se sei una ditta individuale è possibile richiedere un finanziamento ad-hoc, non vincolato a nessuna finalità quindi da utilizzare come preferisci.',
      individual_company_financing_text_2:
        'È possibile richiederlo senza impegno in un minuto per poi essere contattato da un professionista che ha a disposizione molte soluzioni da molti istituti.',
      advance_invoices_title: 'Anticipo Fatture',
      advance_invoices_text:
        'L’anticipo fatture è una tipologia di finanziamento che anticipa la liquidità derivante da fatture con pagamento distante nel tempo, per avere subito a disposizione i fondi senza attesa ed evitare crisi di liquidità.',
      business_consulting_title: 'Consulenza Aziendale',
      business_consulting_text_1:
        'Ogni azienda o ditta ha sicuramente possibilità di migliorare la propria situazione tramite l’utilizzo di appositi strumenti finanziari, alle volte ignorati.',
      business_consulting_text_2:
        'Abbiamo una rete di professionisti disposti su tutto il territorio nazionale che possono aiutarti a crescere nel migliore dei modi. Richiedi un contatto senza alcun impegno!',
      how_does_it_work: 'Chi siamo?',
      oltre_text_1:
        "TassoMutuo è un portale digitale per <b>la comparazione dei migliori mutui sul mercato</b>, con l'obiettivo di raccogliere richieste di <b>finanziamento</b> da parte di <b>privati</b> intenzionati ad acquistare casa.",
      oltre_text_2:
        'Ogni richiesta viene gestita in una prima fase da un <b>back-office</b> dedicato, che svolge le prime verifiche di finanziabilità per poi essere inviato ad un <b>consulente del credito Euroansa</b> di zona per il contatto vero e proprio.',
      oltre_text_3:
        '<b>Euroansa</b> è una grande realtà italiana nell’ambito della mediazione del credito e conta oltre <b>800 professionisti</b> convenzionati con molti <b>Istituti Finanziari e Bancari</b>, ed offre quindi una costellazione di servizi finanziari a portafoglio.',
      active_consultants: 'Consulenti attivi',
      covered_italian_regions: 'Regioni Italiane Coperte',
      affiliated_institutes: 'Istituti Convenzionati',
      financial_products_available: 'Prodotti Finanziari Disponibili',
      request_professionist: '<span class="color-light-green">Miglior mutuo o miglior tasso?</span> TassoMutuo!',
      request_professionist_sub:
        '<span class="color-light-green">1 MINUTO!</span> E’ il tempo che ti basta per fare richiesta di contatto a un Consulente TassoMutuo, <span class="color-light-green">senza impegno</span>.',
      faq_title: 'Hai un dubbio? <span class="color-light-green">TassoMutuo te lo risolve.</span>',
      faq1_q: 'Perchè TassoMutuo?',
      faq1_a:
        'Perché TassoMutuo è reale: hai a disposizione <b>più di 800 consulenti attivi</b> pronti a seguirti passo dopo passo, e ad ascoltare le tue necessità. <b>Utilizza il preventivatore per valutare la tua soluzione ideale</b> e lascia il tuo contatto: ti verrà subito affiancato un Consulente con cui potrai chiarire e risolvere ogni dubbio. <b>Perchè oltre l’online c’è TassoMutuo.</b>',
      faq2_q: 'Per i giovani che vogliono richiedere un mutuo esistono agevolazioni?',
      faq2_a:
        '<b>Gli Under 36 hanno a disposizione le agevolazioni del fondo garanzia prima casa</b>, che permette un accesso facilitato al finanziamento ai giovani con contratto di lavoro atipico, e ai nuclei familiari con un unico reddito.',
      faq3_q: 'Cosa comprende il costo di un mutuo?',
      faq3_a:
        'Sono diverse le componenti del costo di un mutuo. Tra le principali, vanno calcolati <b>gli interessi</b> (il prezzo del finanziamento erogato), <b>le spese notarili</b> in seguito alla stipula del contratto, e <b>le imposte</b>. Per conoscere tutte le componenti del tuo mutuo e le spese detraibili dall’IRPEF, <b>mettiti in contatto con il Consulente specializzato di TassoMutuo</b>.',
      faq4_q: 'Come ottenere un mutuo?',
      faq4_a:
        '<ul><li><b>Confronta i migliori mutui online, valutando le offerte dei diversi istituti bancari operanti sul mercato.</b></li><li>Una volta trovata l’offerta ideale per le tue esigenze, <b>un Consulente specializzato entrerà in contatto con te</b>, guidandoti nell’iter di selezione.</li><li><b>Avviamento dell’istruttoria del mutuo</b>: consegna, presso l’istituto bancario scelto, la documentazione reddituale e anagrafica e la documentazione dell’immobile in oggetto.</li><li><b>Delibera finale</b>: con la delibera finale, la banca dà la sua approvazione finale, indicando le condizioni economiche valide per la stipulazione.</li><li>Per l’erogazione del finanziamento, <b>stipulare il mutuo dal notaio incaricato</b>.</li></ul>',
      faq5_q: 'Quanto può durare un mutuo?',
      faq5_a:
        'E’ previsto per legge che la durata del mutuo può essere di medio o lungo periodo, per cui i mutui possono variare nella durata, <b>dai 5 ai 30 anni</b>. Solo in casi eccezionali si possono registrare mutui da 40 o 50 anni.',
      send_request_to_our_team: 'Lorem ipsum dolor sit <span class="color-yellow">amet ?</span>',
      send_request_to_our_team_unselected: 'Lorem ipsum dolor sit amet?',
      send_request_to_our_team_text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      receive_contact: 'Lorem ipsum dolor sit <span class="color-yellow">amet ?</span>',
      receive_contact_unselected: 'Lorem ipsum dolor sit amet?',
      receive_contact_text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      find_solution: 'Lorem ipsum dolor sit <span class="color-yellow">amet ?</span>',
      find_solution_unselected: 'Lorem ipsum dolor sit amet?',
      find_solution_text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      name: 'Nome',
      last_name: 'Cognome',
      email: 'Email',
      phone: 'Telefono',
      vat_id: 'Partita IVA',
      toc: 'Mandando questo messaggio, confermi di aver letto ed accettato la nostra',
      toc_privacy_policy: 'privacy-policy',
      error_name: 'Per favore, inserisci il nome',
      error_last_name: 'Per favore, inserisci il cognome',
      error_email: 'Per favore, inserisci o controlla la mail',
      error_phone: 'Per favore, inserisci o controlla il numero di telefono',
      error_vat_id: 'Per favore, inserisci o controlla la partita IVA',
      error_toc: 'Per favore, accetta i nostri termini e condizioni',
      form_success_title: 'Richiesta inviata con <span class="color-green">successo!</span>',
      form_success_text: "Tieni d'occhio il tuo cellulare un nostro consulente ti contatterà in 5 minuti!",
      form_success_text_night: "Tieni d'occhio il tuo cellulare un nostro consulente ti contatterà appena possibile!",
      form_error_title: '<span class="color-red">Errore</span> nella richiesta',
      form_error_text: 'Sembra ci sia stato un errore mentre provavamo a inviare la tua richiesta, per favore, riprova a breve',
      estimator_label: 'Hai bisogno di un <span class="color-yellow">Mutuo</span>?',
      estimator_sublabel: 'Prova il preventivatore <span class="color-yellow">O</span>ltre.it e trova la soluzione più adatta alle tue esigenze!',
      estimator_cta: 'Provalo subito',
      lorem:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      text_lorem_1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      text_lorem_2:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      text_lorem_3: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      conventioned_1: 'Oltre ',
      conventioned_2: '130 Istituti Finanziari',
      conventioned_3: ' e ',
      conventioned_4: 'Bancari convenzionati',
      text_fianco_1:'<b>Scegli tra i migliori mutui quello perfetto per te. Un consulente sarà al tuo fianco in 5 minuti!</b>',
      text_fianco_2:'I nostri consulenti ti affiancano nella gestione completa del mutuo:',
      text_fianco_3_li1: 'Ti incontriamo per assicurarci che la soluzione individuata sia davvero quella ideale per te.',
      text_fianco_3_li2: 'Dialoghiamo con banche e intermediari facendo sempre il tuo interesse.',
      text_fianco_3_li3: 'Ti tuteliamo e ti aggiorniamo ad ogni passaggio della tua pratica in modo chiaro e trasparente.',
      text_fianco_3:"Entro 5 minuti sarai contattato dai nostri consulenti specializzati per una consulenza gratuita e l'analisi del tuo caso specifico. ",
      text_fianco_4:
        '<b>Cancella i dubbi, passa Oltre.</b>',
      cta_fianco: 'Mostrami i mutui più vantaggiosi',
      all_italy: 'Tutta Italia',
      coverage: 'Siamo presenti!',
      contact_us_section_title: 'Come possiamo aiutarti?',
      contact_us_section_description: 'Hai bisogno di informazioni? Non esitare a contattarci.',
      contact_us_section_write_us: 'Scrivici',
      how_does_it_work_title: '<span class="color-light-green">Come funziona</span> TassoMutuo?',
      how_does_it_work_text_1: 'Rispondi ad alcune domande, ci servono per fornirti la migliore proposta di Mutuo',
      how_does_it_work_text_2: 'Confronta le diverse proposte e scegli il miglior mutuo per te',
      how_does_it_work_text_3: 'Parla gratuitamente ed entro 5 minuti con il tuo consulente che analizzerà la tua pratica',
      advantages_title: 'Quali sono i vantaggi di <span class="color-light-green">TassoMutuo?</span>',
      advantages_text_1: 'TassoMutuo è un progetto Euroansa che conta oltre 800 professionisti convenzionati con oltre 130 Istituti Finanziari e Bancari',
      advantages_text_2: 'In soli 5 minuti siamo al tuo fianco per valutare la tua pratica e consigliarti sulle condizioni migliori da scegliere',
      advantages_text_3: 'Abbiamo lo stesso obiettivo, farti ottenere il mutuo alle migliori condizioni di mercato',
      advantages_text_4: 'I nostri consulenti hanno successo in più dell\'80% di pratiche contro una media nazionale di circa il 50%',
      main_banner_input_label: 'Inserisci l\'importo del tuo mutuo',
      main_banner_input_text: 'Confronta i Mutui delle migliori banche e trova i Tassi più convenienti',
      main_banner_input_button: 'Calcola',
      most_asked_mutuals: 'I nostri mutui più <span class="color-light-green">richiesti</span>',
      calculate_payment: 'Calcola la tua rata',
      reviews_title: 'Siamo giovani, ma abbiamo già <span class="color-light-green">aiutato tanti come te</span>',
      reviews_description: 'Valutata <b>4.9</b> su 5 sulla base di <span style="text-decoration: underline;">oltre 800 recensioni</span>. Le nostre ultime recensioni.',
    },
  },
};

export default mutuoLiquidita;
