const preventivatore = {
  en: {
    translation: {
      order_for: 'Order for:',
      installment: 'Installment',
      taeg_order: 'TAEG',
      reset: 'Reset',
      mutual_reason: 'Mutual reason:',
      contract_type: 'Contract type:',
      type_of_work: 'Type of work:',
      city: 'City:',
      permanent_contract: 'Permanent contract',
      temporary_contract: 'Temporary contract',
      p_iva: 'Partita iva',
      other: 'Other',
      buy_first_house: 'Buy first house',
      interest_rate: 'Interest rate:',
      fix: 'Fix',
      variable: 'Variable',
      mutual_amount: 'Mutual amount:',
      real_estate_value: 'Real estate value:',
      duration: 'Duration (years):',
      age: 'Age applicant:',
      loan_to_value: 'Loan to value:',
      calculate_payment: 'Calculate payment',
      search_results: 'Search <span class="color-red">results</span>',
      error_importo: 'Please, add a mutual amount',
      error_valore_immobiliare: 'Please, add a real estate value',
      error_eta: 'Please, add how old are you',
      payment: 'Payment',
      payment_amount: 'Payment amount',
      months: ' months',
      show_more_results: 'Show more results',
      show_less_results: 'Close results',
      tan: 'Tasso: ',
      taeg: 'TAEG: ',
      irs: 'IRS/Euribor: ',
      spread: 'Spread: ',
      substitutive_tax: 'Substitutive tax: ',
      substitutive_tax_short: 'Subs tax: ',
      investigation_ext: 'Istruttoria: ',
      investigation: 'Istr.: ',
      expertise: 'Expertise: ',
      mediation: 'Mediation: ',
      incendio: 'Incendio: ',
      request_feasibility: 'Request feasibility',
      general_info: 'General info',
      text: 'Notes',
      error_text: 'Please, add the notes',
      send_request: 'Send request',
      write_placeholder: 'Write...',
      required_field: 'Required field',
      insert_number: 'Insert number',
      obtain_mutual: 'Obtain Mutual',
      insert_valid_email: 'Insert valid email',
      self_employed: 'Self Employed',
      retired: 'Retired',
      insert_valid_city: 'Insert valid city',
      min_three_char: 'Enter at least 3 characters',
      no_city_message: 'Nessuna città trovata...',
      search_city_message: 'Search for your city',
      accept_privacy_wa: 'Accetto di ricevere aggiornamenti in merito alla mia richiesta via WhatsApp',
      accept_privacy:
        'Inviando la richiesta, confermi di aver letto ed accettato la <a href="/privacy-policy" style="text-decoration: underline;" target="_blank">termini e le condizioni</a> e i <a href="/termini-condizioni" style="text-decoration: underline;" target="_blank">termini e le condizioni</a>',
      informativa: 'informativa',
    },
    no_results_title: 'La ricerca non ha prodotto risultati',
    no_results_description:
      'Ma non preoccuparti, un nostro consulente ti contatterà presto per aiutarti a trovare il prodotto più adatto a te!ontattaci lo stesso per parlare con un nostro consulente specializzato!',
    valImm: "Il valore dell'immobile deve essere superiore o almeno uguale al valore del mutuo richiesto",
    update_payment: 'aggiorna ricerca',
  },
  it: {
    translation: {
      order_for: 'Ordina per:',
      installment: 'Rata',
      taeg_order: 'Tasso',
      reset: 'Reset',
      mutual_reason: 'Finalità del mutuo:',
      contract_type: 'Tipo di contratto:',
      type_of_work: 'Tipologia lavorativa:',
      city: 'Città:',
      permanent_contract: 'Contratto indeterminato',
      temporary_contract: 'Contratto determinato',
      p_iva: 'Partita iva',
      other: 'Contratto determinato/apprendistato/stage',
      buy_first_house: 'Acquisto prima casa',
      interest_rate: 'Tasso di interesse:',
      fix: 'Fisso',
      variable: 'Variabile',
      mutual_amount: 'Importo del mutuo:',
      real_estate_value: "Valore dell'immobile:",
      duration: 'Durata:',
      age: 'Età:',
      loan_to_value: 'Percentuale mutuo (LTV):',
      calculate_payment: 'Mostrami i mutui più vantaggiosi',
      search_results:
        'Ecco i <span class="color-light-green">migliori mutui</span> che abbiamo selezionato <span class="color-light-green">per te:</span>',
      search_results_subline:
        'Lo sapevi che <b>i nostri consulenti hanno successo in più dell’80% di pratiche</b> contro una media nazionale di circa il 50%? Con TassoMutuo hai più possibilità di ottenere il tuo mutuo!',
      error_importo: "Per favore, aggiungi l'importo del mutuo",
      error_valore_immobiliare: 'Per favore, aggiungi il valore immobiliare',
      error_eta: "Per favore, aggiungi l'età",
      payment: 'Rata',
      payment_amount: 'Importo rata',
      months: ' mesi',
      show_more_results: 'Mostra altri risultati',
      show_less_results: 'Chiudi risultati',
      tan: 'Tasso: ',
      taeg: 'TAEG: ',
      irs: 'IRS/Euribor: ',
      spread: 'Spread: ',
      substitutive_tax: 'Imp. sost.: ',
      substitutive_tax_short: 'Imposta sost: ',
      investigation_ext: 'Istruttoria: ',
      investigation: 'Istr.: ',
      expertise: 'Perizia: ',
      mediation: 'Mediazione: ',
      incendio: 'Incendio: ',
      request_feasibility: 'Richiedi fattibilità',
      general_info: 'Informazioni generali',
      text: 'Note',
      error_text: 'Per favore aggiungi le note',
      send_request: 'Invia richiesta',
      write_placeholder: 'Scrivi...',
      required_field: 'Campo obbligatorio',
      insert_number: 'Inserisci un numero',
      obtain_mutual: 'Verifica fattibilità',
      obtain_mutual_subline: 'GRATIS E SENZA IMPEGNO',
      insert_valid_email: "Inserisci un'email valida",
      self_employed: 'Autonomo',
      retired: 'Pensionato',
      insert_valid_city: 'Inserisci una città valida',
      min_three_char: 'Inserisci almeno 3 caratteri',
      no_city_message: 'Nessuna città trovata...',
      search_city_message: 'Cerca la tua città',
      accept_privacy_wa: 'Accetto di ricevere aggiornamenti in merito alla mia richiesta via WhatsApp',
      accept_privacy:
        'Inviando la richiesta, confermi di aver letto ed accettato la <a href="/privacy-policy" style="text-decoration: underline;" target="_blank">privacy policy</a> e i <a href="/termini-condizioni" style="text-decoration: underline;" target="_blank">termini e le condizioni</a>',
      no_results_title: 'La ricerca non ha prodotto risultati',
      no_results_description: 'Ma non preoccuparti, un nostro consulente ti contatterà presto per aiutarti a trovare il prodotto più adatto a te!',
      valImm: "Il valore dell'immobile deve essere superiore o almeno uguale al valore del mutuo richiesto",
      update_payment: 'aggiorna ricerca',
      results_0_title: 'ATTENZIONE!',
      results_0_description: 'Sembra che la tua pratica di Mutuo sia particolarmente complessa, infatti non abbiamo risultati da mostrarti.',
      results_0_description_2: 'In questi casi suggeriamo di parlare direttamente con un nostro consulente che troverà con te la soluzione migliore.',
      results_1_title: 'ATTENZIONE!',
      results_1_description: 'Sembra che la tua pratica di Mutuo sia particolarmente complessa, infatti abbiamo solo 1 risultato da mostrarti.',
      results_1_description_2: 'In questi casi suggeriamo di parlare direttamente con un nostro consulente che troverà con te la soluzione migliore.',
      results_2_title: 'ATTENZIONE!',
      results_2_description: 'Sembra che la tua pratica di Mutuo sia particolarmente complessa, infatti abbiamo solo 2 risultati da mostrarti.',
      results_2_description_2: 'In questi casi suggeriamo di parlare direttamente con un nostro consulente che troverà con te la soluzione migliore.',
      informativa: 'informativa',
    },
  },
};

export default preventivatore;
