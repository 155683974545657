/* eslint no-unused-vars: ["warn"] */
import i18next from "i18next";

import resources from './resources';

i18next.init(resources, (err, t) => {
  // initialized and ready to go!
});

export default i18next;
