import getAggregateRatingSchema from './getAggregateRatingSchema';

export default function getOrganizationSchema() {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://www.tassomutuo.it',
    sameAs: 'https://tassomutuo.it',
    logo: 'https://www.tassomutuo.it/logo_tassomutuo_dark.png',
    name: 'TassoMutuo',
    description:
      'Confronta e risparmia sul mutuo online con TassoMutuo. Un Consulente ti affiancherà per tutto il percorso. Confronta le rate per la casa: al resto pensiamo noi.',
    email: 'info@tassomutuo.it',
    telephone: '+393490516905',
    vatID: 'IT04526210960',
    address: {
      '@context': 'https://schema.org',
      '@type': 'PostalAddress',
      name: 'Sede Legale',
      addressLocality: 'Milano',
      addressRegion: 'Lombardia',
      addressCountry: 'IT',
      postalCode: '20121',
      streetAddress: 'Piazza Cavour 7',
    },
    aggregateRating: getAggregateRatingSchema(),
  };

  return schema;
}
