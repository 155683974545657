import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Image from 'next/image';

import { usePopupCtaContext } from '../../context/PopupCtaContext.js';

import logo_tassomutuo_dark from '../../public/logo_tassomutuo_dark.png';

const theme = {
  dark: {
    bgcolor: '#103C55',
    titleColor: 'white',
    ctaColor: '#3799D0',
    ctaTextColor: 'white',
  },
  light: {
    bgcolor: '#3799D0',
    titleColor: 'white',
    ctaColor: 'white',
    ctaTextColor: '#103C55',
  },
  default: {
    bgcolor: 'transparent',
    titleColor: '#3799D0',
    ctaColor: '#3799D0',
    ctaTextColor: 'white',
  },
};

const PopupCta = () => {  

  const [popupCta, setPopupCta] = usePopupCtaContext();
  
  const {actionLink, popupMessage, showPopup, ctaText} = popupCta ? popupCta : {};

  return showPopup ? (
    <Box
      sx={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        overflow: 'hidden',
        zIndex: '999999999',
      }}>
      <Box
        onClick={() => {
          setPopupCta({ ...(popupCta || {}), showPopup: false });
        }}
        sx={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          backdropFilter: 'blur(10px)',
          background: 'rgba(0, 0, 0, 0.4)',
          overflow: 'hidden',
        }}></Box>
      <Box
        sx={{
          background: '#F4F3F4',
          maxWidth: 'min(90%, 700px)',
          minWidth: 'min(90%, 700px)',
          width: 'min(90%, 700px)',
          borderRadius: '10px',
          margin: '0 auto',
          padding: '30px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '9999999999999',
        }}>
        <Image
          src={logo_tassomutuo_dark}
          alt="TassoMutuo logo"
          sizes="(max-width: 768px) 180px, 280px"
          loading="eager"
          style={{ width: '100%', minWidth: '180px', maxWidth: '280px', height: 'auto', marginBottom: '40px' }}
          width={5129}
          height={1000}
        />
        <Typography
          dangerouslySetInnerHTML={{
            __html: popupMessage,
          }}
          sx={{
            fontFamily: 'var(--font-red-hat-display)',
            textAlign: 'center',
            fontSize: '25px',
          }}></Typography>
        {ctaText && (
          <Button
            component="a"
            href="#top"
            variant="contained"
            disableElevation
            onClick={() => {
              setPopupCta({ ...(popupCta || {}), showPopup: false });
            }}
            sx={{
              bgcolor: theme.dark.ctaColor,
              color: theme.dark.ctaTextColor,
              fontFamily: 'var(--font-red-hat-display)',
              fontSize: 'clamp(1.125rem, 0.971rem + 0.493vw, 1.5625rem)',
              mt: 6,
              px: 4,
              '&:hover': {
                bgcolor: theme.dark.ctaTextColor,
                color: theme.dark.ctaColor,
              },
              textDecoration: 'none !important',
            }}>
            {ctaText}
          </Button>
        )}
      </Box>
    </Box>
  ) : null; // <-- se il valore del ls di show popup non c'è elimina
};
export default PopupCta;
