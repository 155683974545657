/* eslint global-require: "warn" */
// import _ from "lodash";
import configuration from './configuration';

// function overwriteConfig() {
//   try {
//     const configurationLocal = require("./local-configuration").default;
//     const mergedConfig = _.merge({}, configuration, configurationLocal);
//     // // console.log('Merged config', mergedConfig);
//     return mergedConfig;
//   } catch (e) {
//     // console.error('No configuration override, using default config.
//     // (No \'proj-root/src/config/local-configuration.js\' found.)', configuration);
//     return configuration;
//   }
// }

// FOR RELEASE BUILDS USE:

const config = configuration.production;
// const config = configuration.development;
// const config = configuration.stagingfoodiestrip;
// const config = configuration.staging;

config.version = '1.7.18';


export default config;
