const configuration = {
  development: {
    environment: 'development',
    baseUrl: 'http://localhost:3008',
    clientUrl: 'http://localhost:3000',
    supportedLanguage: ['it', 'en'],
    supportedRoute: [],
    captchaKey: '6Lf-ui0nAAAAAPSqG_E3kocMQWl1aJBnBqwQjDyV', //test-key
    mixpanelId: '817e42405e5a70a58b8928828e477617',
    mixpanelDebug: true,
    cmsBaseUrl: 'https://cms.tassomutuo.it',
    intranetBaseUrl: 'https://backofficedev.euroansa.it',
  },
  stagingfoodiestrip: {
    environment: 'stagingfoodiestrip',
    baseUrl: 'https://api.tripfoodies.com',
    clientUrl: 'http://test.tripfoodies.com',
    supportedLanguage: ['it', 'en'],
    supportedRoute: [],
    // captchaKey: 'ui0nAAAAAPSqG_E3kocMQWl1aJBnBqwQjDyV',
    captchaKey: '6Lf-ui0nAAAAAPSqG_E3kocMQWl1aJBnBqwQjDyV', //test-key
    mixpanelId: '817e42405e5a70a58b8928828e477617',
    mixpanelDebug: true,
    cmsBaseUrl: 'https://cms.tassomutuo.it',
    intranetBaseUrl: 'https://backofficedev.euroansa.it',
  },
  staging: {
    environment: 'staging',
    baseUrl: 'https://api2.tassomutuo.it',
    clientUrl: 'https://dev.tassomutuo.it',
    supportedLanguage: ['it', 'en'],
    supportedRoute: [],
    captchaKey: '6Lf-ui0nAAAAAPSqG_E3kocMQWl1aJBnBqwQjDyV', //test-key
    mixpanelId: '817e42405e5a70a58b8928828e477617',
    mixpanelDebug: true,
    cmsBaseUrl: 'https://cms.tassomutuo.it',
    intranetBaseUrl: 'https://backofficedev.euroansa.it',
  },
  production: {
    environment: 'production',
    baseUrl: 'https://api.tassomutuo.it',
    clientUrl: 'https://tassomutuo.it',
    supportedLanguage: ['it', 'en'],
    supportedRoute: [],
    captchaKey: '6Ldg1C4nAAAAAB1bck3uQBok9jkBlG0nQoJQHcoN',
    mixpanelId: '50cad616a95bdc722f95cd4c6ebe1e63',
    mixpanelDebug: false,
    cmsBaseUrl: 'https://cms.tassomutuo.it',
    intranetBaseUrl: 'https://backoffice.euroansa.it',
  },
};

export default configuration;
