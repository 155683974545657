import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../config/config';

import logo_tassomutuo from '../public/logo_tassomutuo.png';


const singleLink = item => (
  <div
    key={item.label}
    onClick={() => {
      item.onClick();
    }}
    className="footer-label-block">
    {item.isExternalLink ? (
      <a href={item.link} target="_blank">
        {item.label}
      </a>
    ) : (
      <>{item.label}</>
    )}
  </div>
);

export default function Footer(props) {
  const { language, auth } = props;
  const { t } = useTranslation();

  const phoneNumber = '+39800461042';
  const phoneLabel = '800.46.10.42';

  const router = useRouter();

  const block3 = {
    title: t('footer.links_title'),
    block: [
      {
        onClick: () => {
          router.push(`${language === 'it' ? '/cookie-policy' : '/cookie-policy'}`);
        },
        label: t('footer.cookie_policy'),
        isExternalLink: false,
        link: language === 'it' ? '/cookie-policy' : '/cookie-policy',
      },
      {
        onClick: () => {
          router.push(`${language === 'it' ? '/privacy-policy' : '/privacy-policy'}`);
        },
        label: t('footer.privacy_policy'),
        isExternalLink: false,
        link: language === 'it' ? '/privacy-policy' : '/privacy-policy',
      },
      {
        onClick: () => {
          router.push(`${language === 'it' ? '/termini-condizioni' : '/termini-condizioni'}`);
        },
        label: t('footer.term_condition'),
        isExternalLink: false,
        link: language === 'it' ? '/privacy-policy' : '/privacy-policy',
      },
      {
        onClick: () => {},
        label: t('footer.knoweuroansa'),
        isExternalLink: true,
        link: language === 'it' ? 'https://www.euroansa.it/conosci-euroansa/' : 'https://www.euroansa.it/conosci-euroansa/',
      },
      {
        onClick: () => {},
        label: t('footer.trasparency'),
        isExternalLink: true,
        link: language === 'it' ? 'https://www.euroansa.it/trasparenza-euroansa/' : 'https://www.euroansa.it/trasparenza-euroansa/',
      },
      {
        onClick: () => {},
        label: t('footer.restricted_area'),
        isExternalLink: true,
        link: 'https://dash.tassomutuo.it',
      },

      {
        onClick: () => {},
        label: 'AppRisparmio',
        isExternalLink: true,
        link: 'https://www.apprisparmio.com/',
      },
    ],
  };

  function blockFunction(blockName, lastElement = false) {
    return (
      <div className={`footer-block ${lastElement ? 'footer-block-last-element' : ''}`}>
        <h4 className="footer-title">{blockName.title}</h4>
        {blockName.block.map((el, index) => {
          return <div key={`footer_link_${index.toString()}`}>{singleLink(el)}</div>;
        })}
      </div>
    );
  }

  return (
    <footer className="footer " id="Contacts">
      <div className="footer-container" style={{ justifyContent: 'space-between' }}>
        <div className="footer-text-container">
          <div className="footer-logo-container">
            <Link href="/">
              <Image
                src={logo_tassomutuo}
                alt="TassoMutuo logo"
                sizes="(max-width: 768px) 180px, 280px"
                style={{ width: '100%', minWidth: '180px', maxWidth: '280px', height: 'auto' }}
                width={5129}
                height={1000}
              />
            </Link>
          </div>
          <div className="footer-description">{t('footer.footer_description')}</div>
          <div className="footer-social">
            <a href="https://www.facebook.com/euroansa" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} className="footer-social-icon" />
            </a>
            <a href="https://www.linkedin.com/company/euroansa-spa/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} className="footer-social-icon" />
            </a>
            <a href="https://www.instagram.com/euroansa_s.p.a/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="footer-social-icon" />
            </a>
          </div>
        </div>

        {blockFunction(block3)}
        <div className="footer-block">
          <h4 className="footer-title">{t('footer.contacts_title')}</h4>
          <div className="footer-description">
            <div className="footer-label-block">
              <a href="https://www.euroansa.it/" target="_blank">
                EUROANSA SPA
              </a>
            </div>
          </div>

          <div className="header-phone">
            <br />
            <br />
            <a className="header-phone-link" href={`tel:3490516905`}>
              <FontAwesomeIcon icon={faPhone} className="header-contact-icon" style={{ width: '0.8em' }} />
              3490516905
            </a>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '20px', padding: '20px' }}>
        <hr />
        <p style={{ color: '#fff', fontWeight: '100', fontSize: '16px', letterSpacing: '0.5px' }}>
          Copyright &copy; 2023 Euroansa SPA. Tutti i diritti riservati.
          <br />
          Sede Legale: Piazza Cavour 7, 20121 Milano Mi | P.IVA:04526210960
          <br />
          <small style={{ fontSize: '10px' }}>version {config.version}</small>
        </p>
      </div>
    </footer>
  );
}
