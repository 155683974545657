// import navbar from './navbar';
import footer from './footer';
import header from './header';
import home from './pages/home';
import preventivatore from './pages/preventivatore';
import chiSiamo from './pages/chiSiamo';
import contattaci from './pages/contattaci';
import cookiePolicy from './pages/cookiePolicy';
import mutuiPrimaCasa from './pages/mutuiPrimaCasa';
import mutuiSecondaCasa from './pages/mutuiSecondaCasa';
import mutuoTassoFisso from './pages/mutuoTassoFisso';
import mutuoGiovaniUnder36 from './pages/mutuoGiovaniUnder36';
import mutuoGiovani from './pages/mutuoGiovani';
import mutuoGiovaniPrimaCasa from './pages/mutuoGiovaniPrimaCasa';
import mutuoInps from './pages/mutuoInps';
import mutuoCointestato from './pages/mutuoCointestato';
import mutuoUnder36 from './pages/mutuoUnder36';
import mutuoRistrutturazione from './pages/mutuoRistrutturazione';
import mutuoTassoVariabile from './pages/mutuoTassoVariabile';
import mutuoConsap from './pages/mutuoConsap';
import mutuo100per100 from './pages/mutuo100per100';
import mutuoLiquidita from './pages/mutuoLiquidita';

const resources = {
  lng: 'it',
  defaultLanguage: 'it',
  otherLanguages: ['it'],
  resources: {
    en: {
      translation: {
        test: 'I18next test: english',
        congratulation: 'Thanks for your registering',
        max: 'max',
        people: 'people',
        // NAVBAR
        about: 'About',
        special_products: 'Special Products',
        events: 'Events',
        reserved_area: 'Backoffice',
        live_event: 'SIGN UP NOW',
        find_more: 'Find more',
        find_more_private: 'You are a private person',
        find_more_agency: 'You are a business',
        request: 'Request',
        send: 'Send',
        contact_us: 'Contact us',
        banks: 'Banks',
        months: 'Months',
        try_now: 'Try it now',
        mail_verification: 'Mail verification',
        back_home: 'Back to homepage',
        footer: footer.en.translation,
        home: home.en.translation,
        preventivatore: preventivatore.en.translation,
        header: header.en.translation,
        chiSiamo: chiSiamo.en.translation,
        contattaci: contattaci.en.translation,
        cookiePolicy: cookiePolicy.en.translation,
        mutuiPrimaCasa: mutuiPrimaCasa.en.translation,
        mutuiSecondaCasa: mutuiSecondaCasa.en.translation,
        mutuoTassoFisso: mutuoTassoFisso.en.translation,
        mutuoGiovaniUnder36: mutuoGiovaniUnder36.en.translation,
        mutuoGiovani: mutuoGiovani.en.translation,
        mutuoGiovaniPrimaCasa: mutuoGiovaniPrimaCasa.en.translation,
        mutuoInps: mutuoInps.en.translation,
        mutuoCointestato: mutuoCointestato.en.translation,
        mutuoUnder36: mutuoUnder36.en.translation,
        mutuoRistrutturazione: mutuoRistrutturazione.en.translation,
        mutuoTassoVariabile: mutuoTassoVariabile.en.translation,
        mutuoConsap: mutuoConsap.en.translation,
        mutuo100per100: mutuo100per100.en.translation,
        mutuoLiquidita: mutuoLiquidita.en.translation,

        // navbar: navbar.en.translation,
        // footer: footer.en.translation,
        // PAGE
      },
    },
    it: {
      translation: {
        test: 'I18next test: italian',
        max: 'massimo',
        people: 'persone',
        congratulation: 'Grazie per esserti registrato',
        // NAVBAR
        about: 'About',
        special_products: 'Prodotti Speciali',
        events: 'Eventi',
        reserved_area: 'Backoffice',
        live_event: 'REGISTRATI SUBITO',
        find_more: 'Scopri di più',
        find_more_private: 'Sei un privato',
        find_more_agency: "Sei un'Azienda",
        request: 'Richiedi',
        send: 'Invia',
        contact_us: 'Contattaci',
        banks: 'Banche',
        months: 'Mesi',
        try_now: 'Provalo subito',
        mail_verification: 'Verifica email',
        back_home: 'Torna alla home',
        footer: footer.it.translation,
        home: home.it.translation,
        preventivatore: preventivatore.it.translation,
        header: header.it.translation,
        chiSiamo: chiSiamo.it.translation,
        contattaci: contattaci.it.translation,
        cookiePolicy: cookiePolicy.it.translation,
        mutuiPrimaCasa: mutuiPrimaCasa.it.translation,
        mutuiSecondaCasa: mutuiSecondaCasa.it.translation,
        mutuoTassoFisso: mutuoTassoFisso.it.translation,
        mutuoGiovaniUnder36: mutuoGiovaniUnder36.it.translation,
        mutuoGiovani: mutuoGiovani.it.translation,
        mutuoGiovaniPrimaCasa: mutuoGiovaniPrimaCasa.it.translation,
        mutuoInps: mutuoInps.it.translation,
        mutuoCointestato: mutuoCointestato.it.translation,
        mutuoUnder36: mutuoUnder36.it.translation,
        mutuoRistrutturazione: mutuoRistrutturazione.it.translation,
        mutuoTassoVariabile: mutuoTassoVariabile.it.translation,
        mutuoConsap: mutuoConsap.it.translation,
        mutuo100per100: mutuo100per100.it.translation,
        mutuoLiquidita: mutuoLiquidita.it.translation,


        // navbar: navbar.it.translation,
        // footer: footer.it.translation,

        // PAGE
      },
    },
  },
};

export default resources;
