const cookiePolicy = {
  en: {
    translation: {
      cookie_policy: "Cookie Policy"
    }
  },
  it: {
    translation: {
      cookie_policy: "Cookie Policy"
    }
  }
};

export default cookiePolicy;
